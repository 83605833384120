<template>
  <v-container fluid>
    <v-row ref="filtersContainer">
      <v-col cols="6" class="d-flex align-center">
        <span class="text-h5">Транспортные средства</span>
        <v-btn class="ml-3" style="vertical-align: middle;" rounded color="primary" small
               @click="showForm()"
        >
          <v-icon left>mdi-plus</v-icon> Добавить
        </v-btn>
        <v-dialog v-model="formDialog" width="400px" persistent>
          <transport-form v-if="formDialog"
                            @transport:form:saved="closeForm"
                            @transport:form:cancel="closeForm"
          ></transport-form>
        </v-dialog>
      </v-col>
    </v-row>

    <v-data-table :items="transports"
      :headers="nonSortableHeaders" :loading="!!fetching" :items-per-page="filter.limit"
      :height="dataTableHeight" disable-items-per-page hide-default-footer fixed-header
      no-data-text="Нет данных" loading-text="Данные загружаются"
    >
      <template #item="{item, index}">
        <tr>
          <td class="text-center">{{filter.limit * (filter.page - 1) + index + 1}}</td>
          <td>{{item.number}}</td>
          <td>{{item.model}}</td>
          <td class="text-right">
            <v-btn class="ml-2" text small depressed color="success" @click="showDetails(item)">
              <v-icon left small>mdi-eye</v-icon>
              Показать
            </v-btn>
            <v-btn class="ml-2" icon small depressed color="primary" dark @click="showForm(item)">
              <v-icon small>mdi-pen</v-icon>
            </v-btn>
            <v-btn class="ml-2" icon small depressed color="error" @click="askRemove(item)"
                   :loading="deleting[item.id]"
            >
              <v-icon left small>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>

    <pagination ref="paginationContainer" :limit.sync="limit" :page.sync="page" :total-pages="totalPages"></pagination>

    <v-dialog v-if="detailDialog" fullscreen persistent v-model="detailDialog">
      <transport-details @dialog:close="closeDetails"
                     @dialog:edit="editForm"
      ></transport-details>
    </v-dialog>
  </v-container>
</template>

<script>
  import {createNamespacedHelpers} from 'vuex'

  const {mapState, mapActions, mapMutations} = createNamespacedHelpers('Transport')

  import TransportDetails from '@/modules/transport/details'
  import TransportForm from '@/modules/transport/form'

  import {hasFilter, hasDialog} from '@/mixins'

  export default {
    name: 'transport-list',

    mixins: [hasFilter, hasDialog],

    created() {
      this.fetch(this.filter)
    },

    computed: {
      ...mapState(['data', 'fetching', 'deleting',]),

      headers() {
        return [
          {text: '#', align: 'center', width: '70'},
          {text: 'Номер', width: 200},
          {text: 'Модель'},
          {text: 'Действия', align: 'right', sortable: false},
        ]
      },

      transports() {
        return this.data?.results || []
      },
    },

    methods: {
      ...mapActions(['fetch', 'show', 'remove']),
      ...mapMutations(['setModel']),

      showForm(item) {
        if (item && item.id) {
          this.show(item.id)
        } else {
          this.setModel({})
        }
        this.formDialog = true
      },

      editForm(item) {
        this.showForm(item)
        this.detailDialog = false
      },
    },

    mounted() {
      this.$eventBus.$emit('setTitle', 'Список транспортных средств')
    },

    components: {
      TransportForm,
      TransportDetails,
    }
  }
</script>
