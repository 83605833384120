<template>
  <div>
    <v-row ref="filtersContainer" justify="center" align="center" class="mb-1">
      <v-col cols="6">
        <h2 class="font-weight-regular">История операций</h2>
      </v-col>
      <v-col cols="3">
        <v-menu v-model="date1menu" :close-on-content-click="false"
                transition="scale-transition" offset-y max-width="290px" min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="fromDateFormatted"
                          label="с" prepend-icon="mdi-calendar"
                          readonly v-bind="attrs" v-on="on" hide-details
            ></v-text-field>
          </template>
          <v-date-picker v-model="filter.date_after" @input="dateChanged"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="3">
        <v-menu v-model="date2menu" :close-on-content-click="false"
                transition="scale-transition" offset-y max-width="290px" min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="toDateFormatted"
                          label="по" prepend-icon="mdi-calendar"
                          readonly v-bind="attrs" v-on="on" hide-details
            ></v-text-field>
          </template>
          <v-date-picker v-model="filter.date_before" @input="dateChanged"></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-data-table :loading="!!fetchingHistory" :items-per-page="filter.limit"
                  :items="histories"
                  :headers="nonSortableHeaders"
                  :height="dataTableHeight" disable-items-per-page hide-default-footer fixed-header
    >
      <template #item="{item, index}">
        <tr>
          <td class="text-center">{{filter.limit * (filter.page - 1) + index + 1}}</td>
          <td class="text-center">{{item.payment.reg_date|date}}</td>
          <td class="text-right">{{item.payment.total|money}}</td>
          <td>{{item.payment.comments}}</td>
        </tr>
      </template>
    </v-data-table>

    <pagination ref="paginationContainer" :limit.sync="limit" :page.sync="page" :total-pages="totalPages"></pagination>
  </div>
</template>

<script>
import moment from 'moment'
import {SERVER_DATE_FORMAT, USER_DATE_FORMAT} from '@/filters'
import {mapState} from 'vuex'
import ut from '@/utils'
import {hasFilter, hasDialog} from '@/mixins'

export default {
  name: 'transport-history',

  props: {
    transportId: {
      required: true,
    }
  },

  mixins: [hasFilter, hasDialog],

  customFilters: {
    date_before: moment().format(SERVER_DATE_FORMAT),
    date_after: moment().subtract(1, 'month').format(SERVER_DATE_FORMAT),
    amount_min: '',
    amount_max: '',
  },

  data() {
    return {
      date1menu: false,
      date2menu: false,

      headers: [
        {text: '#', align: 'center', width: '70px'},
        {text: 'Дата', align: 'center', width: '200px'},
        {text: 'Сумма', align: 'right', width: '150px'},
        {text: 'Описание'},
      ]
    }
  },

  computed: {
    ...mapState('Transport', ['history', 'fetchingHistory']),
    ...mapState('Payment', ['PaymentTypes']),

    data() {
      return this.history
    },

    histories() {
      return this.history?.results || []
    },

    meta() {
      return this.history?.type_total || {}
    },


    fromDateFormatted() {
      return moment(this.filter.date_after).format(USER_DATE_FORMAT)
    },

    toDateFormatted() {
      return moment(this.filter.date_before).format(USER_DATE_FORMAT)
    }
  },

  methods: {
    fetch() {
      return this.$store.dispatch('Transport/fetchHistory', {
        ...this.compiledFilters,
        transport: this.transportId,
      })
    },

    dateChanged() {
      this.date1menu = false
      this.date2menu = false
      this.$nextTick(this.fetch)
    },
  },

  created() {
    this.fetch()
  },
}
</script>
