var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{ref:"filtersContainer",staticClass:"mb-1",attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('h2',{staticClass:"font-weight-regular"},[_vm._v("История операций")])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.fromDateFormatted,"label":"с","prepend-icon":"mdi-calendar","readonly":"","hide-details":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.date1menu),callback:function ($$v) {_vm.date1menu=$$v},expression:"date1menu"}},[_c('v-date-picker',{on:{"input":_vm.dateChanged},model:{value:(_vm.filter.date_after),callback:function ($$v) {_vm.$set(_vm.filter, "date_after", $$v)},expression:"filter.date_after"}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.toDateFormatted,"label":"по","prepend-icon":"mdi-calendar","readonly":"","hide-details":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.date2menu),callback:function ($$v) {_vm.date2menu=$$v},expression:"date2menu"}},[_c('v-date-picker',{on:{"input":_vm.dateChanged},model:{value:(_vm.filter.date_before),callback:function ($$v) {_vm.$set(_vm.filter, "date_before", $$v)},expression:"filter.date_before"}})],1)],1)],1),_c('v-data-table',{attrs:{"loading":!!_vm.fetchingHistory,"items-per-page":_vm.filter.limit,"items":_vm.histories,"headers":_vm.nonSortableHeaders,"height":_vm.dataTableHeight,"disable-items-per-page":"","hide-default-footer":"","fixed-header":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.filter.limit * (_vm.filter.page - 1) + index + 1))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm._f("date")(item.payment.reg_date)))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("money")(item.payment.total)))]),_c('td',[_vm._v(_vm._s(item.payment.comments))])])]}}])}),_c('pagination',{ref:"paginationContainer",attrs:{"limit":_vm.limit,"page":_vm.page,"total-pages":_vm.totalPages},on:{"update:limit":function($event){_vm.limit=$event},"update:page":function($event){_vm.page=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }