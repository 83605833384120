<template>
  <v-card :loading="!!fetching">
    <template #progress>
      <v-progress-linear color="primary" indeterminate></v-progress-linear>
    </template>

    <v-card-title>
      Карточка транспорта {{model.number}} - {{model.model}}
      <v-btn color="primary" small dark rounded @click="$emit('dialog:edit', model)" class="ml-3">
        <v-icon small left>mdi-pen</v-icon> Изменить
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('dialog:close')">
        <v-icon color="error">mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text v-if="false">
      <transport-history v-if="!fetching" :transport-id="model.id"></transport-history>
    </v-card-text>
    <v-card-text>
      <h3 class="font-weight-regular">История затрат на ТС</h3>
      <transport-expense-list v-if="!fetching" :transport-id="model.id" embedded hide-actions></transport-expense-list>
    </v-card-text>
  </v-card>
</template>

<script>
  import {createNamespacedHelpers} from 'vuex'
  const {mapState} = createNamespacedHelpers('Transport')

  import TransportHistory from '@/modules/transport/history'
  import TransportExpenseList from '@/modules/transport-expense/list'

  export default {
    name: 'transport-details',

    computed: {
      ...mapState(['fetching', 'model']),
    },

    components: {
      TransportHistory,
      TransportExpenseList,
    }
  }
</script>
